.button-custom-upload {
  background: #888;
  height: 70px;
  width: 70px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
}
.file-upload {
  position: absolute;
  top: 0;
  text-align: center;
  cursor: pointer;
  opacity: 0;
}

.file-upload:hover,
.file-upload:active,
.file-upload:focus {
  background: #eee;
  cursor: pointer;
  opacity: 0.9;
  border-radius: 50%;
}
